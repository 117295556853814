<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader class="text-center bg-dark text-white">
                    <b>{{$t('label.listLostTime')}}</b>
                </CCardHeader>
                <CCardBody>
                    <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">    
                        <CustomTab :title="$t('label.delayTdrCode')">
                            <template #title>
                                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Delay-Tdr.svg" alt="Card image cap">
                                {{$t('label.delayTdrCode')}}                
                            </template>
                            <app-modal-tiempo-perdido />
                            <CRow>
                                <CCol col="12" xl="12">
                                    <CCol sm="12" class="d-flex justify-content-end">
                                        &nbsp;&nbsp;
                                        <CButton
                                            color="add"
                                            v-c-tooltip="{
                                                content: $t('label.lostTime'),
                                                placement: 'top-end'
                                            }"
                                            @click="activarModal()"
                                        >
                                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                        </CButton>
                                    </CCol>
                                    <CCol sm="12">
                                        <dataTableExtended
                                            class="align-center-row-datatable"
                                            hover
                                            sorter
                                            small
                                            column-filter
                                            :table-filter="tableText.tableFilterText"
                                            :items-per-page-select="tableText.itemsPerPageText"
                                            :items="formatedItems"
                                            :fields="fields"
                                            :noItemsView= "tableText.noItemsViewText"
                                            :items-per-page="5"
                                            pagination
                                            :loading="apiStateLoading"
                                             
                                        >
                                            <template #loading>
                                                <loading/>
                                            </template>
                                            <template #IdTiempoPerdido="{ index }">
                                                <td class="center-cell">
                                                    {{index+1}}
                                                </td>
                                            </template>
                                            <template #Status="{item}">
                                                <td class="center-cell">
                                                    <CBadge v-if="item.status" color="success">
                                                        {{$t('label.'+item.Status)}}
                                                    </CBadge>
                                                    <CBadge v-if="!item.status" color="danger">
                                                        {{$t('label.'+item.Status)}}
                                                    </CBadge>
                                                </td>
                                            </template>
                                            <template #Details="{item, index}">
                                                <td class="center-cell">
                                                    <CButton
                                                        color="edit"
                                                        size="sm"
                                                        class="mr-1"
                                                        v-c-tooltip="{
                                                            content: $t('label.edit')+' '+$t('label.lostTime'),
                                                            placement: 'top-end'
                                                        }"
                                                        @click="updateModal(item, index)"
                                                    >
                                                        <CIcon name="pencil"/>
                                                    </CButton>
                                                </td>
                                            </template>
                                        </dataTableExtended>
                                    </CCol>
                                </CCol>
                            </CRow>
                        </CustomTab>
                        <CustomTab :title="$t('label.delayResponsible')">
                            <template #title>
                                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Responsible-Delay.svg" alt="Card image cap">
                                {{$t('label.delayResponsible')}}                
                            </template>
                            <AddResponsible
                            :modal.sync="showModal"
                            @updated-modal="updateCLoseModal"
                            :title="titleModal"
                            :editModal="editModal"
                            :responsibleItem="responsibleItem"
                            @refresh-data-table="getDelayResponsibles"
                            />
                            
                            <CRow>
                                <CCol sm="12" class="d-flex justify-content-end">
                                    &nbsp;&nbsp;
                                    <CButton
                                        color="add"
                                        v-c-tooltip="{
                                            content: $t('label.delayResponsible'),
                                            placement: 'top-end'
                                        }"
                                        @click="toggleAddResponsible()"
                                    >
                                        <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow class="mb-3">
                                <CCol sm="12" >
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        :items="formatedItemsResponsibles"
                                        :fields="fieldsResponsibles"
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        sorter
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        column-filter
                                        hover
                                        size="lg"
                                         
                                    >
                                        <template #RowNumber="{ item }">
                                            <td class="center-cell">
                                                {{ item.RowNumber }}
                                            </td>
                                        </template>
                                        <template #Status="{item}">
                                        <td class="center-cell">
                                            <CBadge :color="getBadge(item.Status)">
                                            {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                        </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <CButton
                                                    color="edit"
                                                    size="sm"
                                                    class="mr-1"
                                                    v-c-tooltip="{
                                                        content: $t('label.edit')+' '+$t('label.delayResponsible'),   
                                                        placement: 'top-end'
                                                    }" 
                                                    @click="toggleEditResponsible(item)"  
                                                >
                                                    <CIcon name="pencil"/>
                                                </CButton>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                        </CustomTab>
                        <CustomTab :title="$t('label.delayCustomCode')">
                            <template #title>
                                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Delay-Custom-Code.svg" alt="Card image cap">
                                {{$t('label.delayCustomCode')}}                
                            </template>
                            <AddCustomCode :modal.sync="modalAdd" @submited="handleSubmit"/>
                            <EditCustomCode :modal.sync="modalEdit" :code-data="customCodeItem" @submited="handleSubmit"/>
                            <CRow>
                                <CCol sm="12" class="d-flex justify-content-end">
                                    &nbsp;&nbsp;
                                    <CButton
                                        color="add"
                                        v-c-tooltip="{
                                            content: $t('label.delayCustomCode'),
                                            placement: 'top-end'
                                        }"
                                        @click="toggleAdd()"
                                    >
                                        <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow class="mb-3">
                                <CCol sm="12" >
                                    <dataTableExtended
     class="align-center-row-datatable"
                                        :items="formatedItemsCodes"
                                        :fields="fieldsCodes"
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        sorter
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        column-filter
                                        hover
                                        size="lg"
                                         
                                    >
                                        <template #RowNumber="{ item }">
                                            <td class="center-cell">
                                                {{ item.RowNumber }}
                                            </td>
                                        </template>
                                        <template #CustomCode="{ item }">
                                            <td class="">
                                                {{ item.CustomCode }}
                                            </td>
                                        </template>
                                        <template #TdrCode="{ item }">
                                            <td class="">
                                                {{ item.TdrCode }}
                                            </td>
                                        </template>
                                        <template #Status="{item}">
                                        <td class="center-cell">
                                            <CBadge :color="getBadge(item.Status)">
                                            {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                        </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <CButton
                                                    color="edit"
                                                    size="sm"
                                                    class="mr-1"
                                                    v-c-tooltip="{
                                                        content: $t('label.edit')+' '+$t('label.delayCustomCode'),   
                                                        placement: 'top-end'
                                                    }" 
                                                    @click="toggleEdit(item)"  
                                                >
                                                    <CIcon name="pencil"/>
                                                </CButton>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                        </CustomTab>
                        <CustomTab :title="$t('label.TimePerTypeVessel')">
                            <template #title>
                                <img class="mr-1" width="100%" height="100%" src="/img/iconos/Tab-Delay-Custom-Code.svg" alt="Card image cap">
                                {{$t('label.TimePerTypeVessel')}}                
                            </template>
                            <CRow>
                                <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
                                    <CButton
                                        color="excel"
                                        class="mr-1" 
                                        size="sm"
                                        @click="onBtnExport(true)"
                                    >
                                        <CIcon name="cil-file-excel"/>&nbsp; XSLX
                                    </CButton>
                                </CCol>
                                <CCol sm="12" class="d-flex justify-content-end">
                                    &nbsp;&nbsp;
                                    <CButton
                                        color="add"
                                        v-c-tooltip="{
                                            content: $t('label.TimePerTypeVessel'),
                                            placement: 'top-end'
                                        }"
                                        @click="ModalTimeTypeVessel=true"
                                    >
                                        <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')}}
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" lg="4">
                                    <CSelect
                                        :label="$t('label.vesselType')"
                                        :horizontal="{ label: 'col-sm-12 col-lg-4 text-right', input: 'col-sm-12 col-lg-7'}"
                                        :options="TpVesselListFormatted"
                                        v-model.trim="TpVesselId"
                                        :value.sync="TpVesselId"
                                        @change="filtre"
                                    />
                                </CCol>
                                <CCol sm="12" lg="6">
                                    <CSelect
                                        :label="$t('label.DelayCustom')"
                                        :horizontal="{ label: 'col-sm-12 col-lg-auto text-right', input: 'col-sm-12 col-lg-8'}"
                                        :options="DelayCustomListFormatted"
                                        v-model.trim="DelayCustomId"
                                        :value.sync="DelayCustomId"
                                        @change="filtre"
                                    />
                                </CCol>
                            </CRow>
                            <CRow class="mb-3">
                                <CCol sm="12" >
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        :items="formatedItemTpVessel"
                                        :fields="fieldsTpVessel"
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        sorter
                                        :noItemsView="tableText.noItemsViewText"
                                        :items-per-page="5"
                                        pagination
                                        column-filter
                                        hover
                                        size="lg"
                                        @filtered-items-change="getFilteredList"
                                    >
                                        <template #Status="{item}">
                                        <td class="center-cell">
                                            <CBadge :color="getBadge(item.Status)">
                                            {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                        </td>
                                        </template>
                                        <template #acciones="{item}">
                                            <td class="center-cell">
                                                <CButton
                                                    color="edit"
                                                    size="sm"
                                                    class="mr-1"
                                                    v-c-tooltip="{
                                                        content: $t('label.edit')+' '+$t('label.TimePerTypeVessel'),   
                                                        placement: 'top-end'
                                                    }" 
                                                    @click="EditTimePerTypeVessel(item)"  
                                                >
                                                    <CIcon name="pencil"/>
                                                </CButton>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                            <ModalTimePerTypeVessel
                                :modal.sync="ModalTimeTypeVessel"
                                :isEdit="isEdit"
                                :TpVesselItem="TpVesselItem"
                                @close-Modal="closeModal"
                            />
                        </CustomTab>
                    </CustomTabs>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
</style>

<script>
    import ENUM from '@/_store/enum';
    import { mapState,mapMutations } from 'vuex';
    import appModalTiempoPerdido from './app-modal-tiempoperdido.vue';
    import ModalTimePerTypeVessel from './modal-time-per-type-vessel';
    import GeneralMixin from '@/_mixins/general';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
    import AddCustomCode from './add-custom-code';
    import EditCustomCode from './edit-custom-code';
    import AddResponsible from './add-responsible';
    import GeneralReport from '@/_mixins/generalReport';

    const dataPais = [];
    function fields() {
        if(this.$i18n.locale=='en'){ 
            return [
                { key: 'IdTiempoPerdido', label: '#', filter:false, _style: 'width:1%; text-align:center' },
                { key: 'DelayDsEn',label: this.$t('label.lostTime')+' (EN)', _style:'width:27%;'},
                { key: 'TpDelayNameEn',label: this.$t('label.type')+' (EN)', _style:'width:25%;'},
                { key: 'TdrCode',label: this.$t('label.TdrCode'), _style:'width:10%;'},
                { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width: 10%;'},
                { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;', _classes:'text-center'},
                { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:'text-center'},
                { 
                    key: 'Details', 
                    label: '', 
                    _style: 'width:50px', 
                    sorter: false, 
                    filter: false
                }
            ];
        }
        if(this.$i18n.locale=='es'){ 
            return [
                { key: 'IdTiempoPerdido', label: '#', filter:false, _style: 'width:1%; text-align:center' },
                { key: 'DelayDsEs',label: this.$t('label.lostTime')+' (ES)', _style:'width:27%;'},
                { key: 'TpDelayNameEs',label: this.$t('label.type')+' (ES)', _style:'width:25%;'},
                { key: 'TdrCode',label: this.$t('label.TdrCode'), _style:'width:10%;'},
                { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width: 10%;'},
                { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;', _classes:'text-center'},
                { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:'text-center'},
                { 
                    key: 'Details', 
                    label: '', 
                    _style: 'width:50px', 
                    sorter: false, 
                    filter: false
                }
            ];
        } 
       
    }

    function fieldsResponsibles() {
        if(this.$i18n.locale=='en'){ 
            return [
                { key: 'RowNumber', label: '#', filter:false, _style: 'width:3%; text-align:center' },
                { key: 'ResponsibleNameEn',label: this.$t('label.responsible')+' (EN)', _style:'width:50%;'},
                { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 12%;'},
                { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
                { key: 'Status', label: this.$t('label.status'), _style:'width:15%;', _classes:'text-center'},
                { 
                    key: 'acciones', 
                    label: '', 
                    _style: 'width:50px', 
                    sorter: false, 
                    filter: false
                }
            ];
        }
        if(this.$i18n.locale=='es'){ 
            return [
                { key: 'RowNumber', label: '#', filter:false, _style: 'width:3%; text-align:center' },
                { key: 'ResponsibleNameEs',label: this.$t('label.name')+' (ES)', _style:'width:50%;'},
                { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 12%;'},
                { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
                { key: 'Status', label: this.$t('label.status'), _style:'width:15%;', _classes:'text-center'},
                { 
                    key: 'acciones', 
                    label: '', 
                    _style: 'width:50px', 
                    sorter: false, 
                    filter: false
                }
            ];
        }       
    }

    function fieldsCodes() {
        if(this.$i18n.locale=='en'){ 
            return [
                { key: 'RowNumber', label: '#', filter:false, _style: 'width:1%; text-align:center' },
                { key: 'DelayDsEn',label: this.$t('label.lostTime')+' (EN)', _style:'width:22%;'},
                { key: 'DelayCustomNameEn',label: this.$t('label.name')+' (EN)', _style:'width:20%;'},
                { key: 'CustomCode',label: this.$t('label.customCode'), _style:'width:10%;'},
                { key: 'TdrCode',label: this.$t('label.TdrCode'), _style:'width:10%;'},
                { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 8%;'},
                { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;', _classes:'text-center'},
                { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:'text-center'},
                { 
                    key: 'acciones', 
                    label: '', 
                    _style: 'width:50px', 
                    sorter: false, 
                    filter: false
                }
            ];
        }
        if(this.$i18n.locale=='es'){ 
            return [
                { key: 'RowNumber', label: '#', filter:false, _style: 'width:1%; text-align:center' },
                { key: 'DelayDsEs',label: this.$t('label.lostTime')+' (ES)', _style:'width:27%;'},
                { key: 'DelayCustomNameEs',label: this.$t('label.name')+' (ES)', _style:'width:25%;'},
                { key: 'CustomCode',label: this.$t('label.customCode'), _style:'width:10%;'},
                { key: 'TdrCode',label: this.$t('label.TdrCode'), _style:'width:10%;'},
                { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 8%;'},
                { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;', _classes:'text-center'},
                { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:'text-center'},
                { 
                    key: 'acciones', 
                    label: '', 
                    _style: 'width:50px', 
                    sorter: false, 
                    filter: false
                }
            ];
        }       
    }

    function fieldsTpVessel() {
        return [
            { key: 'Nro', label: '#', filter:false, _style: 'width:3%; text-align:center' },
            { key: 'TpVesselName',label: this.$t('label.vesselType'), _style:'width:24%;'},
            { key: this.$i18n.locale=='en'?'DelayCustomNameEn':'DelayCustomNameEs',label: this.$t('label.DelayCustom'), _style:'width:24%;'},
            { key: 'CustomCode',label: this.$t('label.customCode'), _style:'width:17%;'},
            { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 10%;'},
            { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 10%;', _classes:'text-center'},
            { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:'text-center'},
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width:45px;',
                sorter: false, 
                filter: false
            }
        ];  
    }


    //data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',
            activePage: 1,
            optionsListFiltro:[{value:"ALL",label:"TODOS"},{value:"ACTIVO",label:"ACTIVOS"},{value:"INACTIVO",label:"INACTIVOS"}],
            //custom codes
            itemsCodes: [], 
            isLoading: false, 
            modalAdd: false,
            modalEdit: false,
            customCodeItem: {},
            modalAddResponsible: false,
            modalEditResponsible: false,
            responsibleItem: {},
            itemsReponsibles: [],
            TpVesselId: '',
            TpVesselOption: [],
            DelayCustomId: '',
            DelayCustomOption: [],
            TpVesselList: [],
            TpVesselItem: {},
            Tab: 0,
            showModal: false,
            editModal: false,
            titleModal: '',
            computedItems: [],
            ModalTimeTypeVessel: false,
            isEdit: false,
            filteredList: [],
        }
    }

    //mounted
    function mounted() {
        this.$store.state.tiempoperdido.apiState = ENUM.LOADED;
        this.$store.commit('tiempoperdido/mutationModal', false);
        this.$store.dispatch('tiempoperdido/getTiempoPerdidolist','ALL');
        this.TpVesselId = '';
        this.DelayCustomId = '';
    }
    
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        //console.log("state", carga)
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function formatedItems() {
        this.isLoading = true;
        this.computedItems = this.$store.getters["tiempoperdido/myDatatable"];
        this.isLoading = false;
        return this.computedItems.filter(item => {
            return item
        })
    }
    
    function formatedItemsResponsibles() {
        let _this = this.$i18n.locale;  

        return this.itemsReponsibles.map((item) => Object.assign({}, item, {
            ResponsibleName: _this=='en' ? item.ResponsibleNameEn : item.ResponsibleNameEs,
            RowNumber: item.Nro,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            _classes: item.Status != 'ACTIVO'? 'table-danger': ''
        }));
    }

    function formatedItemsCodes() {
        return this.itemsCodes.map((item) => Object.assign({}, item, {
            RowNumber: item.Nro,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            _classes: item.Status != 'ACTIVO'? 'table-danger': ''
        }));
    }

    function formatedItemTpVessel() {
        return this.TpVesselList.map((item) => Object.assign({}, item, {
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            _classes: item.Status != 'ACTIVO'? 'table-danger': ''
        }));
    }

    function TpVesselListFormatted(){
        if(this.TpVesselOption.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.TpVesselOption.map(function(e){
                chart.push({
                     value: e.TpVesselId, 
                    label: e.TpVesselName,
                })
            })
            return chart;
        }
    }

    function DelayCustomListFormatted(){
        if(this.DelayCustomOption.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            let _lang = this.$i18n.locale;
            this.DelayCustomOption.map(function(e){
                chart.push({
                    value: e.DelayCustomId, 
                    label: _lang=='en'? e.DelayCustomNameEn : e.DelayCustomNameEs,
                })
            })
            return chart;
        }
    }

    //methods
    async function onBtnExport(valor) {
        this.isLoading = true;
        let currentDate = new Date();
        let Filter = [];
        let ReporExcel = [];
        let tableHeader = {label:[], key:[]};
        let _lang = this.$i18n.locale;
        let DelayCustomName = 'N/A';
        let TpVesselName = 'N/A';

        if(this.nulo!="null" && this.filteredList.length !== 0) {
            let vesselTypeData = this.TpVesselOption.find(item => item.TpVesselId === this.TpVesselId);
            let DelayCustomData = this.DelayCustomOption.find(item => item.DelayCustomId === this.DelayCustomId);

            if (vesselTypeData) {
                TpVesselName = vesselTypeData.TpVesselName;
            };

            if (DelayCustomData) {
                DelayCustomName = _lang=='en'? DelayCustomData.DelayCustomNameEn : DelayCustomData.DelayCustomNameEs;
            };

            this.fieldsTpVessel.map(item=>{
                if (item.key!='acciones') {
                    tableHeader.label.push(item.label);
                    tableHeader.key.push(item.key);
                }
            })

            Filter[0] = this.$t('label.vesselType')+": "+TpVesselName;
            Filter[1] = this.$t('label.DelayCustom')+": "+DelayCustomName;
            Filter[2] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
            Filter[3] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

            for (let i = 0; i < this.filteredList.length; i++) {
                ReporExcel.push({
                    Nro: this.filteredList[i].Nro,
                    TpVesselName: this.filteredList[i].TpVesselName ? this.filteredList[i].TpVesselName : '',
                    DelayCustomNameEn: this.filteredList[i].DelayCustomNameEn ? this.filteredList[i].DelayCustomNameEn : '',
                    DelayCustomNameEs: this.filteredList[i].DelayCustomNameEs ? this.filteredList[i].DelayCustomNameEs : '',
                    CustomCode: this.filteredList[i].CustomCode ? this.filteredList[i].CustomCode : '',
                    TransaLogin: this.filteredList[i].TransaLogin ? this.filteredList[i].TransaLogin : '',
                    FormatedDate: this.filteredList[i].TransaRegDate? DateFormater.formatDateTimeWithSlash(this.filteredList[i].TransaRegDate):'N/A',
                    Status: this.filteredList[i].FgActDelayTpVessel ? this.$t('label.ACTIVO') : this.$t('label.INACTIVO'),
                });   
            }
            await this.getReporExcel(ReporExcel, this.$t('label.TimePerTypeVessel'), valor, Filter, false, tableHeader);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.isLoading = false;
        
    }
    function filtre(){
        this.getDelayCustomTpVessel();
    }
    function handleTab(tab){
        this.Tab = tab;
    }
    function activarModal(){
        this.$store.commit('tiempoperdido/mutationModal', true);
    }
    function updateModal(item,index){
        this.$store.commit('tiempoperdido/mutationModal', true);
        this.$store.commit('tiempoperdido/asignarid', item.DelayId);
    }
    function setRefresh(){
        this.$store.dispatch('tiempoperdido/getTiempoPerdidolist');
    }
    function closeModal(refresh){
        this.ModalTimeTypeVessel = false;
        this.isEdit = false;
        this.TpVesselItem = {};
        if(refresh){
            this.TpVesselId = '';
            this.DelayCustomId = '';
        }
        this.getDelayCustomList();
        this.getTpVesselList();
        this.getDelayCustomTpVessel();
    }
    function getDelayCustomCodes()
    {
        this.isLoading = true;
        this.$http.get('DelayCustom-list?DelayId', {filter: 'ALL'})
            .then((response) => {
                this.itemsCodes = response.data.data;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
                });
            }).then(() => {
                this.isLoading = false;
            });
    }

    function getDelayResponsibles()
    {
        this.isLoading = true;
        this.$http.get('DelayResponsible-list', {filter: 'ALL'})
            .then((response) => {
                this.itemsReponsibles=response.data.data;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
                });
            }).then(() => {
                this.isLoading = false;
            });
    }

    function getDelayCustomTpVessel(){
        this.isLoading = true;
        this.$http.get('DelayCustomTpVessel-list', {TpVesselId: this.TpVesselId, DelayCustomId: this.DelayCustomId})
            .then((response) => {
                this.TpVesselList = response.data.data;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
                });
            }).then(() => {
                this.isLoading = false;
            });
    }

    function getDelayCustomList(){
        this.isLoading = true;
        this.$http.get('DelayCustom-list?DelayId=')
            .then((response) => {
                this.DelayCustomOption = response.data.data;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
                });
            })
    }

    function getTpVesselList(){
        this.$http.get('TpVessel-list')
            .then((response) => {
                this.TpVesselOption = response.data.data;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
                });
            })
    }

    function toggleAdd() {
        this.modalAdd = true;
    }
    function toggleEdit(item) {
        this.customCodeItem = Object.assign({}, item);
        this.modalEdit = true;
    }

    function updateCLoseModal(event) {
        this.showModal = false;
        this.titleModal = '';
        this.editModal = false;
        this.responsibleItem = {};
    }

    function toggleAddResponsible() {
        this.showModal= true;
        this.editModal = false;
        this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.delayResponsible');
    }
    function toggleEditResponsible(item) {
        this.responsibleItem = Object.assign({}, item);
        this.showModal= true;
        this.editModal = true;
        this.titleModal = this.$t('label.edit')+' '+this.$t('label.delayResponsible')+' '+this.responsibleItem.ResponsibleName;

    }
    function EditTimePerTypeVessel(item) {
        this.ModalTimeTypeVessel= true;
        this.isEdit = true;
        this.TpVesselItem = item;
    }
    function handleSubmit(){
        this.getDelayCustomCodes();
    }
    function getFilteredList(arr) {
        this.filteredList = arr;
    }

    //watch
    function apiState(newQuestion,OldQuestion){                
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.tiempoperdido.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){                 
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.tiempoperdido.messageError = '';
                
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' :  this.messageError)
                });
                this.$store.state.tiempoperdido.messageError = '';
                break;
            default:
                break;
        }
    }
    function filtroTiempo(newQuestion,OldQuestion){
        this.$store.dispatch('tiempoperdido/getTiempoPerdidolist',newQuestion);
    }
    export default{
        name: 'app-tiempoperdido',
        mixins: [GeneralMixin, GeneralReport],
        data,
        components: {
            appModalTiempoPerdido,
            ModalTimePerTypeVessel,
            AddCustomCode,
            EditCustomCode,
            CustomTabs,
            CustomTab,
            AddResponsible,
        },
        methods: {
            onBtnExport,
            closeModal,
            filtre,
            handleTab,
            activarModal,
            updateModal,
            setRefresh,
            getDelayCustomCodes,
            getDelayResponsibles,
            getDelayCustomTpVessel,
            getDelayCustomList,
            getTpVesselList,
            toggleEdit,
            toggleAdd,
            toggleEditResponsible,
            toggleAddResponsible, 
            EditTimePerTypeVessel,  
            updateCLoseModal,         
            handleSubmit,
            getFilteredList,
        },
        mounted,
        computed: {
            TpVesselListFormatted,
            DelayCustomListFormatted,
            formatedItems,
            apiStateLoading,
            apiStateFormLoading,
            fields,
            fieldsCodes,
            fieldsTpVessel,
            fieldsResponsibles,
            formatedItemsResponsibles,
            formatedItemsCodes,
            formatedItemTpVessel,
            ...mapState({
                apiState: state => state.tiempoperdido.apiState,
                apiStateForm: state => state.tiempoperdido.apiStateForm,
                filtroTiempo: state => state.tiempoperdido.filtroTiempo,
                messageError: state => state.tiempoperdido.messageError,
            })
        },
        watch:{
            apiState,
            apiStateForm,
            filtroTiempo,
            Tab: async function(val) {
                if(val==1){
                    this.getDelayResponsibles();
                }else if(val==2){
                    this.getDelayCustomCodes();
                }else{
                    this.getDelayCustomList();
                    this.getTpVesselList();
                    this.getDelayCustomTpVessel();
                }
            }
        }
    }
</script>